* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: sans-serif;
}

body {
	background-color: #F9FAFB; /* grey1 */
	color: #333333; /* grey3 */
}
form {
	max-width: 480px;
	margin: 2rem auto;
	padding: 2rem;
	background-color: #F2F4F6; /* grey2.5 */
	border-radius: 0.5rem;
}

